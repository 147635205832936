import type { LearningActivity } from '~/composables/useCourse';

export const useCourseFeedback = () => {

  const feedbackSteps = [0.2, 0.5];
  const configKey = 'feedback-form';

  const calculateProgress = (lessons: LearningActivity[], cases: LearningActivity[], quizzes: LearningActivity[]): number => {
    // TODO: This needs to be removed later
    const forcedProgress = localStorage.getItem('force-progress-debug');

    if (forcedProgress && Number(forcedProgress)) {
      return Number(forcedProgress);
    }

    const contents = lessons.length + quizzes.length + cases.length;
    const completedContents = lessons.filter(lesson => lesson.completed).length + quizzes.filter(quiz => quiz.progress?.passed).length + cases.filter(quiz => quiz.progress?.passed).length;
    return completedContents / contents;
  };

  const storeFeedbackMilestone = (courseId: number, progress: number, feedbackAction: 'remindMeLater' | 'submitted') => {
    const feedbackStatus = getFeedbackStatus();
    const form = feedbackStatus[courseId] || {};

    if (feedbackAction === 'remindMeLater') {
      const feedbackForStep = [...feedbackSteps].reverse().find(step => {
        return progress >= step;
      });

      if (feedbackForStep) {
        form[feedbackForStep] = feedbackAction;
      }
    } else {
      form['submitted'] = true;
    }

    feedbackStatus[courseId] = form;
    localStorage.setItem(configKey, JSON.stringify(feedbackStatus));
  };

  const feedbackNeeded = (courseId: number, progress: number) => {
    const status = getFeedbackStatus();
    const state = status[courseId] || {};

    // check if feedback is already submitted
    if (state['submitted'] === true) {
      return false;
    }

    // check if feedback is skipped for this progress
    const feedbackForStep = [...feedbackSteps].reverse().find(step => progress >= step);

    if (!feedbackForStep) {
      return false;
    }

    return !(state[feedbackForStep] === 'remindMeLater');
  };

  const getFeedbackStatus = () => {
    return JSON.parse(localStorage.getItem(configKey) || '{}');
  };

  return {
    calculateProgress,
    storeFeedbackMilestone,
    feedbackNeeded,
  };

};
